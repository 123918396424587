import { Api } from '@/core/api.js'
import Vue from 'vue'

const api = new Api()

const state = {
    categories: {},
    products: {},
    configuration: false,
    forecast: {},
}

const getters = {
    getStates(state, getters, rootState) {
        return state.states
    },
    getCategories: (state, getters, rootState) => {
        return state.categories
    },
    getProducts: (state, getters, rootState) => {
        return state.products
    },
    getSortedCategories: (state, getters, rootState) => {
        return Object.values(state.categories).sort((a, b) => parseInt(a.order) - parseInt(b.order))
    },
    getSelectedCategory: (state, getters, rootState) => {
        for (var i in state.categories) {
            if (state.categories[i].selected) return state.categories[i]
        }
        return false
    },
    getConfiguration: (state, getters, rootState) => {
        return state.configuration
    },
    getForecast: (state, getters, rootState) => {
        return state.forecast
    }

}

const actions = {
    loadData(context, params) {
        return api.get('pls/products').then(function (response) {
            var products = response.data.products
            var categories = response.data.categories
            context.commit('setProducts', products)
            console.log('categories', categories)
            return context.commit('setCategories', categories)
        })
    },
    loadForecast(context, date) {
        const url = `pls/forecast/${date}`;
        return api.get(url).then(function (response) {
            var forecast = response.data
            return context.commit('setForecast', forecast)
        }) 
    }
}

const mutations = {
    setStates(state, data) {
        state.states = data
    },
    setCategories(state, data) {
        state.categories = data
    },
    setProducts(state, data) {
        state.products = data
    },
    /* selectCategory(state, category) {
        if (category == false || category == 'false') {
            for (var k in state.categories) {
                var cat = state.categories[k]
                cat.selected = false
            }
        } else {
            for (var k in state.categories) {
                var cat = state.categories[k]
                if (cat.id == category.id) {
                    cat.selected = true
                } else {
                    cat.selected = false
                }
            }
        }
        Vue.set(state, 'categories', { ...state.categories })
    }, */
    setConfiguration(state, data) {
        state.configuration = data
    },
    toggleProduct(state, id) {
        state.products[id].status == state.products[id].status;
    },
    setForecast(state, data) {
        state.forecast = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
