import { Api } from '@/core/api.js'
import Vue from 'vue'

import { TIMERPRODUCT_STATUS } from '@/constants'
const api = new Api()

import localforage from 'localforage'
const db_timerproducts = localforage.createInstance({ name: 'alexdb', storeName: 'timerproducts' })
const db_tpcategories = localforage.createInstance({ name: 'alexdb', storeName: 'tp_categories' })
const db_tpstates = localforage.createInstance({ name: 'alexdb', storeName: 'tp_states' })
const db_timerlogs = localforage.createInstance({ name: 'alexdb', storeName: 'tp_timerlogs' })

import i18n from '@/i18n'

const getDefaultState = () => {
    return {
        categories: {},
        states: [],
        timers: [],
        interval: null,
        products: {},
        productsOrdered: {}
    }
}
// initial state
const state = getDefaultState()

const getters = {
    getStates(state, getters, rootState) {
        return state.states
    },

    getCategories(state, getters, rootState) {
        return state.categories
    },

    getSelectedCategory: (state, getters, rootState) => {
        for (var i in state.categories) {
            if (state.categories[i].selected) return state.categories[i]
        }
        return false
    },

    getSortedCategories: (state, getters, rootState) => {
        return Object.values(state.categories).sort((a, b) => parseInt(a.order) - parseInt(b.order))
    },

    getTimers(state, getters, rootState) {
        return state.timers
    },

    getTimersByCategory: (state, getters, rootState) => (categoryId) => {
        var timers = {}

        var columns = {}
        for (var idx in state.states) {
            timers[state.states[idx].id] = []
        }

        for (var idx in state.timers) {
            var timer = state.timers[idx]
            if (!categoryId || (categoryId && categoryId == state.products[timer.reference_id].category_id)) {
                if (timer.status == TIMERPRODUCT_STATUS.EXPIRED) {
                    timers[false].push(timer)
                } else {
                    timers[timer.state].push(timer)
                }
            }
        }
        return timers
    },
    getFilteredTimers: (state, getters, rootState) => (categoryId, stateId) => {
        var timers = []

        for (var idx in state.timers) {
            var timer = state.timers[idx]
            if (!categoryId || (categoryId && categoryId == timer.category)) {
                timers[timer.state].push(timer)
            }
        }
        return timers
    },
    getLabelStates: (state, getters, rootState) => (id) => {
        var states = {}
        for (var i in state.products) {
            var product = state.products[i]
            for (var j in product.states) {
                if (!Object.keys(states)[product.states[j].id]) {
                    states[product.states[j].id] = product.states[j]
                }
            }
        }
        if (typeof id !== 'undefined') return states[id]
        return states
    },
    getProducts: (state, getters, rootState) => (id) => {
        return id ? (state.products[id] ? state.products[id] : false) : state.products
    },
    getProductsOrdered: (state, getters, rootState) => {
        return state.productsOrdered
    }
}

const actions = {
    loadSchema(context, params) {
        // TODO - API request
        var columns = [
            { id: '1', name: 'Cámara', qty: 3, color: 'ok' },
            { id: '2', name: 'Mesa', qty: 3, color: 'ok' },
            { id: false, name: 'Desechar', qty: 2, color: 'error' }
        ]
        // var categories = {
        //     false: { id: false, name: 'Todos' },
        //     '1': { id: '1', name: 'Salsas' },
        //     '2': { id: '2', name: 'Vegetales' },
        //     '3': { id: '3', name: 'Otros' },
        //     '4': { id: '4', name: 'Postres' },
        //     '5': { id: '5', name: 'Carne' }
        // }

        return context.commit('setStates', columns)
        // return context.commit('setCategories', categories)
    },
    loadTimers(context, params) {
        if (fnCheckConnection()) {
            return api
                .get('timerproducts/timers', {})
                .then(function (response) {
                    if (state.interval) {
                        clearInterval(state.interval)
                    }

                    var timers = response.data
                    for (var key in timers) {
                        timers[key].status = TIMERPRODUCT_STATUS.AVAILABLE
                    }

                    var interval = setInterval(function () {
                        return context.dispatch('processTimers')
                    }, 1000)

                    context.commit('setInterval', interval)
                    log('loadTimers -> timers: ', timers)
                    context.commit('setTimers', timers)
                    return context.dispatch('updateCategories')
                })
                .catch((error) => {
                    logError(error)
                    context.dispatch('loadTimersOffline')
                })
        } else if (context.rootGetters['getRefresh'].products) {
            context.dispatch('loadTimersOffline')
        }
    },

    loadTimersOffline(context) {
        let timers = {}
        db_timerlogs
            .iterate(function (value, key, iterationNumber) {
                timers[key] = value
            })
            .then(() => {
                return context.commit('setTimers', timers)
            })
    },
    processTimers(context) {
        var products = {}
        var updated = false
        for (var idx in state.timers) {
            var timer = state.timers[idx]

            if (timer.status > 0) {
                // Only process active timers
                var now = moment()

                // Timer prep date
                if (timer.prep_date && timer.status >= TIMERPRODUCT_STATUS.PREPARATION) {
                    var duration = moment.duration(moment(timer.prep_date * 1000).diff(now))
                    var diff = duration.asSeconds()
                    if (diff <= 0) {
                        timer.status = TIMERPRODUCT_STATUS.AVAILABLE
                        updated = true
                    }
                }

                // Timer expiration date
                var duration = moment.duration(moment(timer.data.date_timestamp * 1000).diff(now))
                var diff = duration.asSeconds()
                if (diff <= 0) {
                    timer.status = TIMERPRODUCT_STATUS.EXPIRED
                    // timer.state = false
                    updated = true
                } else if (diff <= 60 * 60) {
                    // Timer is less than 1 hour of expiration
                    timer.status = TIMERPRODUCT_STATUS.CLOSE_TO_EXPIRE
                    updated = true
                }
            }

            // Update timer status of related product
            if (typeof products[timer.product_id] === 'undefined') {
                products[timer.product_id] = []
            }

            if (products[timer.product_id].length > 0) {
                timer.same_product_status = products[timer.product_id][0].status

                for (var idx in products[timer.product_id]) {
                    // products[timer.product_id][idx].same_product_status = timer.status
                    state.timers[products[timer.product_id][idx].id].same_product_status = timer.status
                }
                updated = true
            }
            products[timer.product_id].push(timer)
        }

        if (updated) {
            return context.commit('setTimers', { ...state.timers })
        }
    },
    selectCategory(context, categoryId) {
        var categories = state.categories
        Object.keys(categories).forEach((key) => (categories[key].selected = false))

        categories[categoryId].selected = true
        return context.commit('setCategories', { ...categories })
    },

    updateCategories(context) {
        var categories = state.categories
        var timers = state.timers
        var products = state.products
        Object.keys(categories).forEach((key) => (categories[key].qty = 0))

        for (var idx in timers) {
            var timer = timers[idx]
            categories[products[timer.reference_id].category_id].qty += 1
            categories[false].qty += 1
        }

        return context.commit('setCategories', { ...categories })
    },
    loadTimerProducts(context, params) {
        log('loadTimerProducts -> REFRESH: ', context.rootGetters['getRefresh'].timerproducts)
        log('loadTimerProducts -> Connection: ', fnCheckConnection())
        if (context.rootGetters['getRefresh'].timerproducts && fnCheckConnection()) {
            return api
                .get('timerproducts', {})
                .then(function (response) {
                    var data = format(response.data.categories)
                    var dataDefault = format(response.data.default)
                    log('loadTimerProducts -> data: ', data)
                    context.commit('setCategories', data.categories)
                    context.commit('setStates', data.states)
                    context.commit('setProducts', data.products)
                    context.commit('setRefreshTimerProducts', false, { root: true })
                    // context.commit('setPredefined', dataDefault.categories);

                    db_tpcategories.clear()
                    db_timerproducts.clear()
                    db_tpstates.clear()

                    _.forEach(_.values(data.categories), function (value, key) {
                        db_tpcategories.setItem(value.id, value)
                    })

                    _.forEach(_.values(data.states), function (value, key) {
                        db_tpstates.setItem(value.id, value)
                    })

                    _.forEach(_.values(data.products), function (value, key) {
                        db_timerproducts.setItem(value.id, value)
                    })

                    if (context.rootGetters.getIsApp && parseInt(response.data.update) == 1) {
                        context.dispatch('processLabelsImage', data.products)
                    }

                    context.rootState.refresh.timerproducts = false
                })
                .catch((error) => {
                    logError(error)
                    // loadLabelsOffline(context)
                })
        } else if (context.rootGetters['getRefresh'].products) {
            // loadLabelsOffline(context)
        }
    },

    processLabelsImage(context, params) {
        var aImages = []
        var aLocalImages = []
        // console.log('processLabelsImage -> ', context.getters['getImagesDownloaded'])
        for (var k in params) {
            var label = params[k]
            if (label.image && label.image != 'false' && label.image != 'null') {
                if (!context.getters['getImagesDownloaded'] || (label.last_update && context.getters['getImagesDownloaded'] < moment(label.last_update).valueOf())) {
                    // console.log('processLabelsImage -> label: ', label)
                    // console.log('processLabelsImage -> label.last_update: ', moment(label.last_update).valueOf())
                    // console.log('processLabelsImage -> getImagesDownloaded: ', context.getters['getImagesDownloaded'])
                    // console.log('DOWNLOAD IMAGE')
                    aImages.push(label)
                } else {
                    // console.log('NO DOWNLOAD IMAGE, VALIDATE ON LOCAL')
                    aLocalImages.push(label)
                }
            } else {
                // console.log('NO PRODUCT IMAGE')
            }
        }

        return context.dispatch('queueDownloadImages', aImages).then(() => {
            return context.dispatch('queueLocalImages', aLocalImages)
        })
    },

    queueLocalImages(context, queue) {
        return new Promise((resolve, reject) => {
            if (queue.length > 0) {
                var label = Object.assign({}, queue.pop())
                var imageName = label.image.split('/')
                imageName = imageName[imageName.length - 1]
                // console.log('queueDownloadImage -> imageName: ', imageName)
                window.resolveLocalFileSystemURL(
                    context.rootGetters.getPathFiles + 'images/' + imageName,
                    function (fileEntry) {
                        fileEntry.file(
                            function (entry) {
                                // console.log('IMAGEN EXISTE!: ', entry)
                                label.local_image = context.rootGetters.getPathFiles + 'images/' + imageName
                                db_products.setItem(label.id, label)
                                context.commit('setLabel', label)
                                return context.dispatch('queueLocalImages', queue)
                            },
                            function (e) {
                                // console.log('E2 Local Images - ', e)
                                return context.dispatch('queueLocalImages', queue)
                            }
                        )
                    },
                    function (e) {
                        // console.log('E1  Local Images - ', e)
                        return context.dispatch('queueLocalImages', queue)
                    }
                )
            } else {
                resolve()
            }
        })
    },

    queueDownloadImages(context, queue) {
        return new Promise((resolve, reject) => {
            if (queue.length > 0) {
                var label = queue.pop()
                var imageName = label.image.split('/')
                imageName = imageName[imageName.length - 1]
                log('queueDownloadImage -> imageName: ', imageName)
                window.resolveLocalFileSystemURL(
                    context.rootGetters.getPathFiles + 'images/' + imageName,
                    function (fileEntry) {
                        fileEntry.file(
                            function (entry) {
                                // console.log(entry)
                                return context.dispatch('downloadLabelImage', { uri: label.image, finalPath: context.rootGetters.getPathFiles + 'images/' + imageName, label: label }).then(() => {
                                    return context.dispatch('queueDownloadImages', queue)
                                })
                            },
                            function (e) {
                                // console.log('E2 - ', e)
                                return context.dispatch('downloadLabelImage', { uri: label.image, finalPath: context.rootGetters.getPathFiles + 'images/' + imageName, label: label }).then(() => {
                                    return context.dispatch('queueDownloadImages', queue)
                                })
                            }
                        )
                    },
                    function (e) {
                        // console.log('E1 - ', e)
                        return context.dispatch('downloadLabelImage', { uri: label.image, finalPath: context.rootGetters.getPathFiles + 'images/' + imageName, label: label }).then(() => {
                            return context.dispatch('queueDownloadImages', queue)
                        })
                    }
                )
            } else {
                context.commit('setLastLabelUpdate', moment().valueOf())
                resolve()
            }
        })
    },

    downloadLabelImage(context, labelImage) {
        return new Promise((resolve, reject) => {
            var fileTransfer = new FileTransfer()
            fileTransfer.download(
                labelImage.uri,
                labelImage.finalPath,
                function (entry) {
                    // console.log('Successful download...')
                    // console.log('download complete: ' + entry.toURL())

                    var label = Object.assign(labelImage.label)
                    label.local_image = labelImage.finalPath
                    db_products.setItem(label.id, label)
                    context.commit('setLabel', label)
                    resolve()
                },
                function (error) {
                    log('download error source ' + error.source)
                    log('download error target ' + error.target)
                    log('upload error code' + error.code)

                    resolve()
                },
                null, // or, pass false
                {}
            )
        })
    },

    addTimer(context, params) {
        if (fnCheckConnection()) {
            return api.post('timerproduct/timers/add', params).then(function (response) {
                if (response && response.status) {
                    params.id = response.data.id
                    params.reference_id = params.product
                    params.data = JSON.parse(params.data)
                    params.status = 1
                    context.commit('setTimer', params)
                    return true
                } else {
                    params.id = 'tp_' + moment().valueOf()
                    params.reference_id = params.product
                    params.data = JSON.parse(params.data)
                    params.status = 1
                    context.commit('setTimer', params)
                    const currentTime = getCurrentTime(context)
                    params.current_print_time = currentTime
                    db_timerlogs.setItem(moment().valueOf(), params)
                    return false
                }
            })
        } else {
            params.id = 'tp_' + moment().valueOf()
            params.reference_id = params.product
            params.data = JSON.parse(params.data)
            params.status = 1
            context.commit('setTimer', params)

            const currentTime = getCurrentTime(context)
            params.current_print_time = currentTime
            db_timerlogs.setItem(moment().valueOf(), params)
        }
    },

    moveTimerNextState(context, params) {
        console.log('moveTimerNextState -> params: ', params)
        let timer = JSON.parse(JSON.stringify(params.timer))
        let nextState = params.nextState

        timer.state = nextState
        let nextStateData = context.getters.getProducts(timer.reference_id).states[nextState]

        let data = timer.data
        data.print_date = moment(getCurrentTime(context)).format('DD/MM/YYYY HH:mm')
        data.print_date_timestamp = moment(data.print_date, 'DD/MM/YYYY HH:mm').valueOf() / 1000
        data.print_date_day = moment(data.print_date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
        data.print_date_time = moment(data.print_date, 'DD/MM/YYYY HH:mm').format('H:mm')
        data.print_date_d1 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
        data.print_date_d2 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
        data.print_date_d3 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
        data.print_date_d4 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
        data.print_date_d5 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
        data.print_date_d6 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
        data.print_date_d7 = moment(data.print_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '
        data.state = context.getters.getStates.filter((state) => {
            return state.id == nextState
        })[0].name
        data.currentday = moment().format('DD/MM/YYYY 00:00')
        data.currentday_timestamp = moment().format('DD/MM/YYYY 00:00').valueOf()
        data.currenttime = data.print_date
        data.currenttime_timestamp = data.print_date_timestamp
        data.note1 = nextStateData.info && nextStateData.info.note1 ? nextStateData.info.note1 : ''
        data.note2 = nextStateData.info && nextStateData.info.note2 ? nextStateData.info.note2 : ''
        data.note3 = nextStateData.info && nextStateData.info.note3 ? nextStateData.info.note3 : ''

        let caducidad = moment(data.caducidad_timestamp * 1000)
        data.date = moment(data.print_date, 'DD/MM/YYYY HH:mm').add(nextStateData.time, 'minutes').format('DD/MM/YYYY HH:mm')
        if (moment(data.date, 'DD/MM/YYYY HH:mm').isAfter(caducidad)) {
            data.date = caducidad.format('DD/MM/YYYY HH:mm')
        }
        data.date_timestamp = moment(data.date, 'DD/MM/YYYY HH:mm').valueOf() / 1000
        data.date_day = moment(data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
        data.date_time = moment(data.date, 'DD/MM/YYYY HH:mm').format('H:mm')
        data.date_d1 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
        data.date_d2 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
        data.date_d3 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
        data.date_d4 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
        data.date_d5 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
        data.date_d6 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
        data.date_d7 = moment(data.date, 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '

        data.prep_date = moment(data.print_date, 'DD/MM/YYYY HH:mm').add(nextStateData.preptime, 'minutes').format('DD/MM/YYYY HH:mm')
        if (moment(data.prep_date, 'DD/MM/YYYY HH:mm').isAfter(caducidad)) {
            //TODO SHOW ERROR CAN NOT GENERATE TIMER
        }
        data.prep_date_timestamp = moment(data.prep_date, 'DD/MM/YYYY HH:mm').valueOf() / 1000
        data.prep_date_day = moment(data.prep_date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
        data.prep_date_time = moment(data.prep_date, 'DD/MM/YYYY HH:mm').format('H:mm')
        data.prep_date_d1 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
        data.prep_date_d2 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
        data.prep_date_d3 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
        data.prep_date_d4 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
        data.prep_date_d5 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
        data.prep_date_d6 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
        data.prep_date_d7 = moment(data.prep_date, 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '

        let dateParams = ['print_date', 'currentday', 'currenttime', 'date', 'prep_date']
        dateParams.forEach((date) => {
            for (let i = 0; i < 4; i++) {
                let aux = moment(data[date], 'DD/MM/YYYY HH:mm')
                if (aux.isValid()) {
                    let day = i18n.t('calendar.days')[aux.day()].toUpperCase()
                    if (i > 0) {
                        data[date + '_wd' + i] = day.slice(0, i)
                    } else {
                        data[date + '_wd'] = day
                    }
                } else {
                    // IN CASE INVALID DATE, GENERATE DAY INFO WITH CURRENT DAY
                    let day = i18n.t('calendar.days')[moment().day()].toUpperCase()
                    if (i > 0) {
                        data[date + '_wd' + i] = day.slice(0, i)
                    } else {
                        data[date + '_wd'] = day
                    }
                }
            }
        })

        timer.data = data

        if (fnCheckConnection()) {
            let info = {
                state: nextState,
                reference_id: timer.reference_id,
                data: JSON.stringify(data)
            }
            return api.post('timerproduct/timers/' + timer.id + '/move', info).then(function (response) {
                if (response && response.status) {
                    timer.id = response.data.id
                    timer.status = 1
                    context.commit('setTimer', timer)
                    return true
                } else {
                    params.id = 'tp_' + moment().valueOf()
                    params.reference_id = params.product
                    params.data = JSON.parse(params.data)
                    params.status = 1
                    context.commit('setTimer', params)
                    const currentTime = getCurrentTime(context)
                    params.current_print_time = currentTime
                    db_timerlogs.setItem(moment().valueOf(), params)
                    return false
                }
            })
        } else {
            params.id = 'tp_' + moment().valueOf()
            params.reference_id = params.product
            params.data = JSON.parse(params.data)
            params.status = 1
            context.commit('setTimer', params)

            const currentTime = getCurrentTime(context)
            params.current_print_time = currentTime
            db_timerlogs.setItem(moment().valueOf(), params)
        }
    },

    deleteTimer(context, params) {
        if (fnCheckConnection()) {
            return api.get('timerproduct/timers/' + params.id + '/remove').then(function (response) {
                if (response && response.status) {
                    context.commit('deleteTimer', params.id)
                    return true
                } else {
                    return false
                }
            })
        } else {
            db_timerlogs.removeItem(params.id)
            return context.commit('deleteTimer', params)
        }
    }
}

function format(raw) {
    var categories = {
        false: { id: false, name: 'Todos', selected: false, qty: 0, visible: false }
    }
    var products = {}
    var states = []
    var currentStatesSetted = []

    var found = false

    for (var k in raw) {
        var cat = raw[k]
        cat.visible = false
        cat.selected = false
        cat.qty = 0
        if (!found) {
            // cat.selected = true;
            found = true
        }
        categories[cat.id] = cat

        for (var i in cat.references) {
            var reference = cat.references[i]
            reference.category_id = cat.id
            products[reference.id] = reference

            for (var key in reference.states) {
                if (typeof currentStatesSetted[key] == 'undefined') {
                    states.push({ id: reference.states[key].id, name: reference.states[key].name, qty: 0, color: 'ok', order: parseInt(reference.states[key].order) })
                    currentStatesSetted[key] = true
                }
            }
        }
    }

    states.push({ id: false, name: 'Desechar', qty: 0, color: 'error', order: 9999 })

    states.sort((a, b) => a.order - b.order)

    return { categories: categories, products: products, states: states }
}

function getCurrentTime(context) {
    let response = ''
    let date = moment()

    const serverTimeDifference = context.rootGetters['getServerTimeDifference']
    const timezone = context.rootGetters['getTimeZoneName']
    const dateLimitation = context.rootGetters['getDateLimitation']

    const options = {
        timeZone: timezone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }

    const copy = moment(date)
    const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
    const objectDate = {}

    for (const item of dateTimeFormat) {
        objectDate[item.type] = item.value
    }

    const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`

    date = moment(dateString, 'DD/MM/YYYY HH:mm')

    if (dateLimitation == 0 && date.diff(copy, 'minutes') != serverTimeDifference) {
        const difference = serverTimeDifference - date.diff(copy, 'minutes')
        date = date.add(difference, 'minutes')
    }

    response = date.format('YYYY-MM-DD HH:mm')

    return response
}

const mutations = {
    setStates(state, data) {
        state.states = data
    },
    setCategories(state, data) {
        state.categories = data
    },
    setTimer(state, data) {
        let timers = state.timers
        timers[data.id] = data
        state.timers = timers
    },
    setTimers(state, data) {
        state.timers = data
    },
    deleteTimer(state, data) {
        let timers = state.timers
        delete timers[data]
        state.timers = timers
    },
    setInterval(state, data) {
        state.interval = data
    },
    setProducts(state, data) {
        state.products = data

        var ordered = {}
        var aux = Object.values(data)
        aux.sort((a, b) => a.name.localeCompare(b.name, 'es', { numeric: true }))
        for (var i in aux) {
            var lab = aux[i]
            ordered[lab.id] = lab
        }
        // console.log("ordered");
        // console.log(JSON.stringify(ordered));
        state.productsOrdered = ordered
    },

    selectCategory(state, category) {
        if (category == false || category == 'false') {
            for (var k in state.categories) {
                var cat = state.categories[k]
                cat.selected = false
            }
        } else {
            for (var k in state.categories) {
                var cat = state.categories[k]
                if (cat.id == category.id) {
                    cat.selected = true
                } else {
                    cat.selected = false
                }
            }
        }
        Vue.set(state, 'categories', { ...state.categories })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
